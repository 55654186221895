import { randomKey } from "./crypto";

const animals = [
    'alligator', 'anteater', 'armadillo', 'auroch', 'axolotl', 'badger', 'bat', 'beaver', 'buffalo',
    'camel', 'chameleon', 'cheetah', 'chipmunk', 'chinchilla', 'chupacabra', 'cormorant', 'coyote',
    'crow', 'dingo', 'dinosaur', 'dog', 'dolphin', 'duck', 'elephant', 'ferret', 'fox', 'frog', 'giraffe',
    'gopher', 'grizzly', 'hedgehog', 'hippo', 'hyena', 'jackal', 'ibex', 'ifrit', 'iguana', 'koala', 'kraken',
    'lemur', 'leopard', 'liger', 'llama', 'manatee', 'mink', 'monkey', 'narwhal', 'nyan cat', 'orangutan'
].map((animal) => animal.charAt(0).toUpperCase() + animal.slice(1));

const trees = [
    'acacia', 'alder', 'apple', 'ash', 'aspen', 'avocado', 'bamboo', 'banana', 'baobab', 'beech', 'bilberry',
    'blackberry', 'blackwood', 'blueberry', 'brazil nut', 'cedar', 'cherry', 'chestnut', 'cinnamon', 'coconut',
    'cottonwood', 'cypress', 'date palm', 'dragonfruit', 'ebony', 'elderberry', 'fig', 'fir', 'ginkgo', 'grape',
    'guava', 'hazelnut', 'hickory', 'ironwood', 'jackfruit', 'jambul', 'jujube', 'kiwi', 'kola nut', 'lemon',
    'lime', 'lychee', 'mango', 'maple', 'mangrove', 'mulberry', 'oak', 'olive', 'orange', 'palm', 'papaya',
]

const adjectives = [
    'autumn', 'hidden', 'bitter', 'misty', 'silent', 'empty', 'dry', 'dark', 'summer', 'icy', 'delicate',
    'quiet', 'white', 'cool', 'spring', 'winter', 'patient', 'twilight', 'dawn', 'crimson', 'wispy', 'weathered',
    'blue', 'billowing', 'broken', 'cold', 'damp', 'falling', 'frosty', 'green', 'long', 'late', 'lingering',
    'bold', 'little', 'morning', 'muddy', 'old', 'red', 'rough', 'still', 'small', 'sparkling', 'throbbing',
    'shy', 'wandering', 'withered', 'wild', 'black', 'young', 'holy', 'solitary', 'fragrant', 'aged', 'snowy',
    'proud', 'floral', 'restless', 'divine', 'polished', 'ancient', 'purple', 'lively', 'nameless'
].map((adjective) => adjective.charAt(0).toUpperCase() + adjective.slice(1));

export function createTemporaryUserName(): string {
    return `${adjectives[Math.floor(Math.random() * adjectives.length)]} ${animals[Math.floor(Math.random() * animals.length)]}`;
}

export function createTemporarySceneName(): string {
    return `${adjectives[Math.floor(Math.random() * adjectives.length)]} ${trees[Math.floor(Math.random() * trees.length)]}`;
}

/**
 * The user as stored in the browser.
 * Can be an anonymous user or a logged in user. Thus all the fields are optional.
 */
export class User {
    id?: string;
    email?: string;
    company?: string;
    name?: string;

    constructor() {
        this.name = createTemporaryUserName()
    }

    static randomKey() {
        return "us" + randomKey();
    }
}


/**
 * Represents user known to the backend API.
 * Can not be anonymous.
 */
export class AuthenticatedUser {
    id: string;
    email: string;
    company?: string;
    name?: string;
}

export function isAuthenticatedUser(
    user: unknown,
): user is AuthenticatedUser {
    if (!user) return false;
    if (typeof user !== "object") return false;
    if (!("id" in user)) return false;
    if (!("email" in user)) return false;
    if (typeof user.id !== "string") return false;
    if (typeof user.email !== "string") return false;

    return true;
}


export function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

