import ImageIcon from '@mui/icons-material/AspectRatio';
import SceneIcon from '@mui/icons-material/Category';
import ChatIcon from '@mui/icons-material/Chat';
import EnvironmentIcon from '@mui/icons-material/Language';
import CameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import MaterialIcon from '@mui/icons-material/SportsSoccer';
import { Paper } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useCallback, useEffect, useState } from "react";
import Chat from './Chat';
import FTextField from './FComponents/FTextField';
import MaterialProperties from "./MaterialProperties";
import ResizableContainer from "./ResizableContainer";
import SceneTree from './SceneTree';
import TransformationProperties from './TransformationProperties';
import { User } from './utils/user';
import { CameraAlt } from '@mui/icons-material';
import AssetManager from './AssetManager';

const minDrawerWidth = 200;
const maxDrawerWidth = 1000;
const breakPointHeight = 600;

const dragger =
{
    width: "5px",
    cursor: "ew-resize",
    padding: "4px 0 0",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1200,
    backgroundColor: "#ffffff"
}

export enum Mode {
    scene = 0,
    materials = 1,
    envs = 2,
    cameras = 3,
    frames = 4,
    renders = 5,
    chat = 6,
}

export type SceneDrawerProps = {
    sceneId: string,
    drawerWidth: number,
    onDrawerWidthChange: (width: number) => void,
    onNodeSelected: (nodeId: number) => void,
    sceneTree: any,
    cameraList: any,
    height: number,
    selectedNodes: number[],
    materialTypes: any,
    user: User
}

export default function SceneDrawer({ sceneId, drawerWidth, onDrawerWidthChange, onNodeSelected, sceneTree, cameraList, height, selectedNodes, materialTypes, user }: SceneDrawerProps) {

    const [sectionFocus, setSectionFocus] = useState(0)
    const [selectedMaterial, setSelectedMaterial] = useState();
    const [expanded, setExpanded] = useState('panel1');
    const [maxHeightOfTopAccordion, setMaxHeightOfTopAccordion] = useState(height - 50);

    const [sceneAccordionExpanded, setSceneAccordionExpanded] = useState(true);
    const [propertiesAccordionExpanded, setPropertiesAccordionExpanded] = useState(true);

    const [mode, setMode] = useState<Mode>(Mode.scene);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChange = (_event, newTabIndex) => {
        setMode(newTabIndex);
    };

    const handleNodeSelection = e => {
        onNodeSelected(e);
    }

    // when changing selectedNodes we'll pull for material description an put in selectedMaterial
    useEffect(() => {

        console.log("SceneDrawer::useEffect::selectedNodes", selectedNodes)

        if (selectedNodes != undefined && window.lys != undefined) {
            const json = window.lys.getMaterialFromNodeId(parseInt(selectedNodes[0]));
            if (json != 0)
                setSelectedMaterial(JSON.parse(json));
            else
                setSelectedMaterial(undefined);
        }
    }, [selectedNodes])


    const [detailTabIndex, setDetailTabIndex] = useState(0);

    const handleDetailTabChange = (event, newTabIndex) => {
        setDetailTabIndex(newTabIndex);
    };


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        window.getSelection().removeAllRanges();
        const newWidth = document.body.offsetWidth - e.clientX;// - document.body.offsetLeft;
        console.log(document.body.offsetWidth - e.clientX);
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            onDrawerWidthChange(newWidth);

        }
    }, []);




    return (
        <Paper square={true} sx={{ height: "100%" }}>
            <Box
                width={drawerWidth}
                height={height}
                display="flex"
                sx={{ height: "100%" }}
                flexDirection="column"
                justifyContent="flex-start">

                {(height > breakPointHeight) &&
                    <Tabs variant="fullWidth" value={mode.valueOf()} onClick={e => { setSectionFocus(0); }} onChange={handleChange} aria-label="icon tabs example">
                        <Tab icon={<SceneIcon />} label="Scene" aria-label="scene" sx={{ minWidth: 50, textTransform: 'none', }} />
                        <Tab icon={<MaterialIcon />} label="Materials" aria-label="materials" sx={{ minWidth: 50, textTransform: 'none', }} />
                        <Tab icon={<EnvironmentIcon />} label="Envs" aria-label="environments" sx={{ minWidth: 50, textTransform: 'none', }} />
                        <Tab icon={<CameraIcon />} label="Cameras" aria-label="cameras" sx={{ minWidth: 50, textTransform: 'none', }} />
                        <Tab icon={<ImageIcon />} label="Frames" aria-label="image" sx={{ minWidth: 50, textTransform: 'none', }} />
                        <Tab icon={<CameraAlt />} label="Renders" aria-label="image" sx={{ minWidth: 50, textTransform: 'none', }} />
                        <Tab icon={<ChatIcon />} label="Chat" aria-label="chat" sx={{ minWidth: 50, textTransform: 'none', }} />
                    </Tabs>
                }


                <FTextField

                    sx={{
                        padding: 1
                    }}
                    id="standard-bare"

                    InputProps={{
                        startAdornment: (
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                />

                {mode === Mode.chat && sceneId && (<ResizableContainer sx={{ height: "100%" }}>
                    <Chat sceneId={sceneId} user={user} cameraPosition='1,1,0.5' />
                </ResizableContainer>)}

                {mode === Mode.renders && sceneId && (<ResizableContainer>
                    <AssetManager selectedPath={`home/Renders/${sceneId}.renders`} showFolders={false} recursive={true} filetypes={'.jpg'} sx={{ height: "100%" }} />
                </ResizableContainer>)}

                {mode === Mode.scene && sceneId && (<ResizableContainer>
                    <SceneTree sceneTree={sceneTree} cameraList={cameraList} onNodeSelected={handleNodeSelection} selectedNodes={selectedNodes} />
                    {selectedNodes.length > 0 &&
                        <>
                            <Tabs variant="fullWidth" value={detailTabIndex} onClick={e => { setSectionFocus(1); }} onChange={handleDetailTabChange} aria-label="icon tabs example">

                                <Tab label="Position" sx={{ minWidth: 50, fontSize: 12, fontWeight: 400, textTransform: 'none' }} />
                                <Tab label="Material" sx={{ minWidth: 50, fontSize: 12, fontWeight: 400, textTransform: 'none' }} />
                                <Tab label="Properties" sx={{ minWidth: 50, fontSize: 12, fontWeight: 400, textTransform: 'none' }} />

                            </Tabs>

                            <Box style={{ width: '100%' }}>

                                {detailTabIndex == 0 && <TransformationProperties selectedNodes={selectedNodes} />}
                                {detailTabIndex == 1 && selectedMaterial && <MaterialProperties
                                    selectedMaterial={selectedMaterial}
                                    materialTypes={materialTypes}
                                    onMaterialChange={setSelectedMaterial} />}


                            </Box>
                        </>
                    }
                </ResizableContainer>)}

            </Box>
        </Paper>
    )
}
