import { ArrowCircleUp } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { AppBar, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import { isMobileOnly } from "react-device-detect";
import AssetManager from "./AssetManager";
import FButton from "./FComponents/FButton";
import UserAvatar from "./UserAvatar";
import { AuthenticatedUser, createTemporarySceneName } from "./utils/user";
import { createAndGotoNewScene } from './utils/scene';
import { useNavigate, useParams } from 'react-router-dom';
import { ChangeEvent, useContext, useRef } from 'react';
import { ImportDataContext } from './utils/import-data-context';

export function changeExtension(destPath: string, extension: string) {
    return destPath.replace(/\.[^/.]+$/, extension);
}

export default function FilesPage({ user }: { user: AuthenticatedUser }) {

    const navigate = useNavigate();
    const { '*': rest } = useParams()

    const handleAddSceneClick = async () => {
        createAndGotoNewScene(createTemporarySceneName(), navigate);
    };

    const inputFile = useRef(null)
    const { setImportData } = useContext(ImportDataContext);
     
    const onImportClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };
    
    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const reader = new FileReader();
        const filename = e.target.files[0].name;

        reader.onload = async (e: ProgressEvent<FileReader>) => {
            setImportData({ filename, buffer: e.target.result as ArrayBuffer });
            createAndGotoNewScene(changeExtension(filename, ""), navigate);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }


    function handleSelectedPath(path: string): void {
        navigate(`/files/${path}`, { replace: false });
    }

    return (
        <Box
            style={{
                display: "flex",
                height: "100%",
                width: "100%",
                flexDirection: "column",
            }}
        >
            <AppBar enableColorOnDark color="neutralbackground" position={"fixed"} sx={isMobileOnly ? { top: 'auto', bottom: 0, paddingBottom: "25px" } : {}} elevation={0}>
                <Toolbar disableGutters={isMobileOnly ? undefined : true} variant="dense" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <UserAvatar user={user} />
                    {user.name}
                    <Box sx={{ flex: 1 }}></Box>
                    <FButton
                        startIcon={<AddBoxIcon />}
                        size="small"
                        variant="contained"
                        tooltip="New Empty Scene"

                        onClick={handleAddSceneClick}
                        sx={{
                            marginRight: 1,
                        }}
                    >
                        New Scene
                    </FButton>

                    <input type='file' id='file'
                        onChange={onFileChange} ref={inputFile} style={{ display: 'none' }} />
                    <FButton
                        startIcon={<ArrowCircleUp />}
                        tooltip="Import 3D/CAD files"
                        sx={{
                            marginRight: 1,
                        }}
                        onClick={onImportClick}
                    >
                        Import File
                    </FButton>
                </Toolbar>
            </AppBar>

            <Toolbar variant="dense" ></Toolbar>
            <AssetManager
                showFolders={true}
                recursive={false}
                filetypes=""
                selectedPath={rest}
                onSelectedPathChange={handleSelectedPath}
            ></AssetManager>
        </Box>
    );
}
