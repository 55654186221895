import { createContext, useState } from 'react';

export const ImportDataContext = createContext(null);

export type ImportData = {
  filename: string, 
  buffer: ArrayBuffer,
}

export const ImportDataProvider = ({ children }) => {
  const [importData, setImportData] = useState<ImportData | null>(null);

  return (
    <ImportDataContext.Provider value={{ importData, setImportData }}>
      {children}
    </ImportDataContext.Provider>
  );
};
