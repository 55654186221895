import { createContext, useEffect } from "react";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import LibraryDrawer from "./LibraryDrawer";
import Menu from "./Menu";
import SceneDrawer from "./SceneDrawer";
import WebGLCanvas from "./WebGLCanvas";

import { Button, Snackbar, useTheme } from "@mui/material";

import { Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useLoaderData, useNavigate } from "react-router-dom";
import { User } from "./utils/user";
import { Spinner } from "./Spinner";

const appbarHeight = isMobile ? 74.5 : 49.5;

const defaultDrawerWidth = 350;
const initialDrawerWidth = isMobile ? 0 : defaultDrawerWidth;

export type Access = "can view" | "can edit" | "no access";
export interface SceneMeta {
    realPath: string,
    name: string,
    access: Access,
}

export type CurrentScene = {
    latestRender: number,
}

export const CurrentSceneContext = createContext<CurrentScene>(undefined);

export type FigurementAppProps = { sceneId: string, user: User, canEdit: boolean, themeMode: any, onThemeModeChange: any };

export default function FigurementApp({ sceneId, user, canEdit, themeMode, onThemeModeChange }: FigurementAppProps) {

    const [menuIconCommand, setMenuIconCommand] = useState();
    const [centerAndFit, setCenterAndFit] = useState(false);
    const [renderMode, setRenderMode] = useState();
    const [imageSaved, setImageSaved] = useState<string | null>(null);
    const [renderSaving, setRenderSaving] = useState<boolean>(false);
    const handle = useFullScreenHandle();

    const navigate = useNavigate()

    const [sceneDrawerWidth, setSceneDrawerWidth] =
        useState(initialDrawerWidth);
    const [libraryDrawerWidth, setLibraryDrawerWidth] =
        useState(initialDrawerWidth);
    const [showSceneDrawer, setShowSceneDrawer] = useState(
        initialDrawerWidth != 0
    );
    const [showLibraryDrawer, setShowLibraryDrawer] = useState(
        initialDrawerWidth != 0
    );

    const [sceneTree, setSceneTree] = useState();
    const [materialList, setMaterialList] = useState();
    const [cameraList, setCameraList] = useState();

    const [selectedNodes, setSelectedNodes] = useState([]); // list of strings - ints
    const [loadPrefabModelName, setPrefabModelName] = useState("");
    const [materialTypes, setMaterialTypes] = useState([]);

    const [activeUsersList, setActiveUsersList] = useState();
    const [filename, setFilename] = useState("");

    const [screenHeight, setScreenHeight] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);

    const [breakPoint, setBreakPoint] = useState("");

    const [currentlyDraggedMaterial, setCurrentlyDraggedMaterial] = useState();

    const [materialThumbnail, setMaterialThumbnail] = useState();
    const [materialThumbnails, setMaterialThumbnails] = useState([]);
    const [latestRender, setLatestRender] = useState<number>(Date.now())

    const [wasmLoaded, setWasmLoaded] = useState(false);


    useEffect(() => {
        console.log("materialList update useEffect");

        if (showLibraryDrawer) {
            if (window.lys) window.lys.updateMaterialThumbnails();
        }
    }, [materialList]); //notice the empty array here

    const setSceneTreeAndSceneRoute = (tree) => {
        setSceneTree(tree);
    };

    const setLibraryDrawerVisibility = (visible) => {
        // is this one called on mobile?
        if (!visible) {
            setLibraryDrawerWidth(0);
        } else {
            if (breakPoint == "sm" || breakPoint == "xs") {
                setSceneDrawerVisibility(false);
            }
            setLibraryDrawerWidth(defaultDrawerWidth);
        }
        setShowLibraryDrawer(visible);
        console.log("show!!", visible);
    };

    const onRenderClick = async () => {
        await saveRenderAndThumbnail();
    }

    const setSceneDrawerVisibility = (visible) => {
        // is this one called on mobile?
        if (!visible) {
            setSceneDrawerWidth(0);
        } else {
            if (breakPoint == "sm" || breakPoint == "xs") {
                setLibraryDrawerVisibility(false);
            }
            setSceneDrawerWidth(defaultDrawerWidth);
        }
        setShowSceneDrawer(visible);
    };

    const theme = useTheme();

    const handleResize = () => {
        let height = 0;
        let width = 0;

        if (isMobile) {
            // && CSS.supports("height: 100dvh")
            //`calc(100dvh - ${appbarHeight}px)`;
            width = document.documentElement.clientWidth;
            height = document.documentElement.clientHeight;
        } else {
            //`calc(100vh - ${appbarHeight}px)`;
            width = window.innerWidth;
            height = window.innerHeight;
        }

        setScreenHeight(height - appbarHeight + 1);
        setScreenWidth(width);

        let b = "";

        if (width <= theme.breakpoints.values.sm) {
            b = "xs";
        } else if (width <= theme.breakpoints.values.md) {
            b = "sm";
        } else {
            b = "md";
        }
        setBreakPoint(b);

        // console.log("height ", height-appbarHeight+1);
        // console.log("breakpoint ", b);
        // console.log("width ", width);

        // if (b=="sm" || b=="xs")

        if (showLibraryDrawer && showSceneDrawer && (b == "sm" || b == "xs")) {
            setLibraryDrawerVisibility(false);
        }
    };

    const handleFilenameChange = (name: string) => {
        if (name !== filename) {
            setFilename(name);
            window.lys.setSceneName(name);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {

            window.removeEventListener("resize", handleResize);
            console.log("FigurementApp unmount");
        };

    }, []); //notice the empty array here

    const sceneMeta = useLoaderData() as SceneMeta;
    useEffect(() => {
        setFilename(sceneMeta.name);
        const savingSceneId = sceneId;
        return () => {
            if (savingSceneId != sceneId) return;
            console.log("UNMOUNT: saving thumb")
            saveThumbnail(savingSceneId).then(() => setLatestRender(Date.now()))            
        }
    }, [sceneId])

    async function saveRenderAndThumbnail() {
        // setRenderSaving(true);
        const renderFrame = await window.lys.getCurrentImage();

        if (!renderFrame) {
            console.warn("No pixels in render buffer. Not saving render.")
            return;
        }

        const form = new FormData();
        form.set("full", renderFrame.full);
        form.set("thumbnail", renderFrame.thumbnail);

        fetch("/api/scenes/" + sceneId + "/renders", {
            method: "POST",
            body: form,
        }).then((response) => response.text()
        ).then((renderId: string) => setImageSaved(renderId))   
            .finally(() => setRenderSaving(false));     
    }

    async function saveThumbnail(sceneId: string): Promise<Response> {
        console.log("asked to save thumb");
        // setRenderSaving(true);
        if (!window.lys) return; // Perhaps lys has already been unloaded by reloading the page

        const renderFrame = await window.lys.getCurrentImage();
        if (!renderFrame) {
            console.warn("No pixels in render buffer. Not saving thumbnail.")
            return;
        }

        console.log("Took thumbnail image")
        const form = new FormData();
        form.set("thumbnail", renderFrame.thumbnail);

        return fetch("/api/scenes/" + sceneId + "/renders", {
            method: "POST",
            body: form,
        }).finally(() => setRenderSaving(false));
    }

    const action = (
        <Button color="secondary" size="small" onClick={() => navigate(`/files/home/Renders/${sceneId}.renders`)}>
            See all renders
        </Button>
    );
    return (
        <FullScreen handle={handle}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={!!imageSaved}
                autoHideDuration={6000}
                message="Render saved"
                action={action}                
                onClose={() => setImageSaved(null)}
            />            
            <Menu
                onFullScreen={handle}
                isfullScreen={handle.active}
                onIconClick={setMenuIconCommand}
                onCenterAndFitClick={setCenterAndFit}
                onRenderClick={onRenderClick}
                onSelectPrefabModel={setPrefabModelName}
                onShowLibraryDrawer={setLibraryDrawerVisibility}
                onShowSceneDrawer={setSceneDrawerVisibility}
                libraryDrawerOpen={showLibraryDrawer}
                sceneDrawerOpen={showSceneDrawer}
                user={user}
                sceneId={sceneId}
                activeUsersList={activeUsersList}
                sceneName={filename}
                onChangeSceneName={handleFilenameChange}
                themeMode={themeMode}
                canEdit={canEdit}
                onThemeModeChange={onThemeModeChange} 
                onRenderModeClick={setRenderMode}
            />

            {!isMobileOnly && <Toolbar variant="dense" />}
            <CurrentSceneContext.Provider value={{ latestRender }}>
                <Grid
                    container
                    sx={{ width: "100%", height: "100%", maxHeight: "100%" }}
                >
                    {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}

                    <Grid xs={12} sm={libraryDrawerWidth > 0 ? 6 : 12} md={"auto"}>
                        {showLibraryDrawer && canEdit && (
                            <LibraryDrawer
                                onDraggingMaterial={setCurrentlyDraggedMaterial}
                                height={screenHeight}
                                materialList={materialList}
                                materialThumbnails={materialThumbnails}
                                drawerWidth={
                                    breakPoint == "md"
                                        ? libraryDrawerWidth
                                        : undefined
                                }
                                materialTypes={materialTypes}
                                onDrawerWidthChange={setLibraryDrawerWidth}
                                user={user}
                            />
                        )}
                    </Grid>

                    {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}
                    <Grid
                        xs={12}
                        sm={sceneDrawerWidth > 0 || libraryDrawerWidth > 0 ? (canEdit ? 6 : 12) : 12}
                        md={true}
                    >
                        {/* breakPoint=="md" || breakPoint=="sm" ? screenHeight : sceneDrawerWidth>0 || libraryDrawerWidth>0 ? screenWidth*(3/4) : screenHeight */}
                        <Box
                            height={
                                breakPoint == "md" || breakPoint == "sm"
                                    ? screenHeight
                                    : (((sceneDrawerWidth > 0 || libraryDrawerWidth > 0) && canEdit)
                                        ? screenHeight * 0.5
                                        : screenHeight)
                            }
                            sx={{ overflow: "hidden" }}
                        >
                            {!wasmLoaded && (<Spinner />)}
                            {renderSaving && (<Spinner transparent />)}
                            <WebGLCanvas
                                A={sceneDrawerWidth + libraryDrawerWidth}
                                mouseMode={menuIconCommand}
                                centerAndFit={centerAndFit}
                                onCenterAndFitDone={setCenterAndFit}
                                render={renderMode}
                                selectedNodes={selectedNodes}
                                sceneId={sceneId}
                                onMaterialThumbnailUpdate={setMaterialThumbnail}
                                loadPrefabModelName={loadPrefabModelName}
                                user={user}
                                onWasmLoaded={setWasmLoaded}
                                onSceenTreeChange={setSceneTreeAndSceneRoute}
                                onSceneNameUpdate={setFilename}
                                onMaterialListChange={setMaterialList}
                                onActiveUsersListChange={setActiveUsersList}
                                onCameraListChange={setCameraList}
                                setMaterialTypes={setMaterialTypes}
                                onNodeSelected={setSelectedNodes}
                                currentlyDraggedMaterial={currentlyDraggedMaterial}
                                onMajorVisualUpdate={() => setTimeout(async () => {
                                    await saveThumbnail(sceneId);
                                    setLatestRender(Date.now());
                                }, 500)}
                            />
                        </Box>
                    </Grid>
                    {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}
                    <Grid xs={12} sm={6} md={"auto"}>
                        {showSceneDrawer && canEdit && (
                            <SceneDrawer
                                user={user}
                                // breakPoint=="xs" ? screenHeight-screenWidth*(3/4)-1 :
                                height={
                                    breakPoint == "xs"
                                        ? screenHeight * 0.5
                                        : screenHeight
                                }
                                sceneTree={sceneTree}
                                cameraList={cameraList}
                                breakPoint={breakPoint}
                                drawerWidth={
                                    breakPoint == "md"
                                        ? sceneDrawerWidth
                                        : undefined
                                }
                                onDrawerWidthChange={setSceneDrawerWidth}
                                onNodeSelected={setSelectedNodes}
                                selectedNodes={selectedNodes}
                                materialTypes={materialTypes}
                                sceneId={sceneId}
                            />
                        )}
                    </Grid>
                </Grid>
            </CurrentSceneContext.Provider>
            {isMobileOnly && <Toolbar variant="dense" />}
        </FullScreen>
    );

}
