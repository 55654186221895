import SearchIcon from "@mui/icons-material/SearchOutlined";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FTextField from "./FComponents/FTextField";
import ResizableContainer from "./ResizableContainer";

import FolderIcon from "@mui/icons-material/Folder";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import { GridCloseIcon, GridDeleteIcon } from "@mui/x-data-grid";
import { useRef } from "react";
import FButton from "./FComponents/FButton";
import { isMobile } from "react-device-detect";
import { useComponentSize } from "./utils/use-component-size";
import { FSEntry } from "./utils/fs";
import { Download } from "@mui/icons-material";
import { CurrentSceneContext } from "./FigurementApp";


export default function AssetManager({
    showFolders,
    recursive,
    filetypes,
    selectedPath,
    onSelectedPathChange,
}: {
    showFolders: boolean;
    recursive: boolean;
    filetypes: string;
    selectedPath: string,
    onSelectedPathChange?: (path: string) => void;
}) {
    const [askDeleteScene, setAskDeleteScene] = useState<string | null>(null);
    const [askDeleteRender, setAskDeleteRender] = useState<string | null>(null);
    const [renderPath, setRenderPath] = useState<string | null>(null);

    const navigate = useNavigate();

    const handleClickOnThumbnail = (e: Event, file: FSEntry) => {
        e.stopPropagation();
        e.preventDefault();
        if (file.type === "scene") {
            navigate(`/scenes/${file.id}/edit`);
        } else {
            setRenderPath(file.id);
        }
    };

    // layout of images
    const imageContainerRef = useRef(null);
    const { width: imageContainerwidth } = useComponentSize(imageContainerRef);
    const [imageColumns, setImageColumns] = useState(1);
    useEffect(() => {
        setImageColumns(Math.floor(imageContainerwidth / 128));
    }, [imageContainerwidth]);

    const componentRef = useRef(null);
    const { width: componentWidth } = useComponentSize(componentRef);
    const [orientation, setOrientation] = useState("vertical");
    useEffect(() => {
        if (componentWidth > 600) setOrientation("horizontal");
        else setOrientation("vertical");
    }, [componentWidth]);

    const [folderTree, setFolderTree] = useState([]);

    useEffect(() => {
        if (!showFolders) return;
        // Fetch data from the server
        const url = "/api/folders";

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((jsonData) => {
                setFolderTree(jsonData);            
                const level1 = jsonData.map((d: FSEntry) => d.id);
                const level2 = jsonData.flatMap((d: FSEntry) => d.children.map((c: FSEntry) => c.id));
                const firstTwoLevels = level1.concat(level2);
                setExpandedTreeFolders(firstTwoLevels);
            })
    }, [showFolders]); 

    const currentScene = useContext(CurrentSceneContext);

    useEffect(() => {
        setSelectedTreeFolder(selectedPath);
        loadFilesForFolder(selectedPath);
    }, [selectedPath, currentScene])

    const [fileList, setFileList] = useState([]);
    const [folderList, setFolderList] = useState([]);

    const clickOnFolderIcon = async (event: any, id: string) => {
        handleClickOnTreeViewItem(event, id);
        navigate(`/files/${id}`, { replace: true });
    };

    // Helper function to find parent IDs for a given node
    const findParentIds = (nodes: any[], targetId: string, parentPath = []) => {
        for (const node of nodes) {
            if (node.id === targetId) {
                return parentPath;
            }
            if (node.children) {
                const path = findParentIds(node.children, targetId, [
                    ...parentPath,
                    node.id,
                ]);
                if (path) {
                    return path;
                }
            }
        }
        return "";
    };

    const [selectedTreeFolder, setSelectedTreeFolder] = useState<string>("");
    const [expandedTreeFolders, setExpandedTreeFolders] = useState<string[]>(
        [],
    );

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedTreeFolders(itemIds);
        event.stopPropagation();
    };

    const handleTreeExpansionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
    ) => {
        if (selectedTreeFolder != itemId)
            handleClickOnTreeViewItem(event, itemId);
    };

    function loadFilesForFolder(id: string) {
        const url = new URL(`/api/files/${id}`, window.location.href);
        if (recursive) url.searchParams.append("recursive", "true");
        url.searchParams.append("filetypes", filetypes);
        if (showFolders) url.searchParams.append("includeFolders", "true");

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((jsonData) => {
                if (showFolders) {
                    setFileList(sortFiles(jsonData));
                    setFolderList(jsonData.filter((obj: FSEntry) => obj.type === "folder"));
                } else {
                    setFileList(sortFiles(jsonData));
                }
            })
    }

    function sortFiles(jsonData: any): FSEntry[] {
        let files = jsonData.filter((obj: FSEntry) => obj.type !== "folder");
        if (files.every(f => f.type === "render")) {
            files = files.sort((a, b) => a.modifiedAgo - b.modifiedAgo);
        }
        return files;
    }
    const handleClickOnTreeViewItem = async (_event: any, id: any) => {
        setSelectedTreeFolder(id);

        const newIds = Array.from(
            new Set([
                ...expandedTreeFolders,
                ...findParentIds(folderTree, id, []),
            ]),
        );

        setExpandedTreeFolders(newIds);

        loadFilesForFolder(id);

        if (!!onSelectedPathChange) { onSelectedPathChange(id); }
    };


    function handleDelete({ type, id }): void {
        if (type === "scene") {
            setAskDeleteScene(id);
        } else {
            setAskDeleteRender(id);
        }
    }

    function confirmedDeleteScene() {
        fetch(`/api/scenes/${askDeleteScene}`, {
            method: "DELETE",
        })
            .then((response) => {
                setAskDeleteScene(null)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }                
            })
            .then(() => {
                handleClickOnTreeViewItem(null, selectedTreeFolder);
            })
    }

    function confirmedDeleteRender() {
        fetch(askDeleteRender, {
            method: "DELETE",
        })
            .then((response) => {
                setAskDeleteRender(null)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }                
            })
            .then(() => {
                handleClickOnTreeViewItem(null, selectedTreeFolder);
            })
    }

    function downloadRender(renderPath: string) {
        const url = new URL(renderPath, window.location.href);
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "render.png";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }

    return (
        <>
            <Dialog open={!!askDeleteScene} onClose={() => setAskDeleteScene(null)}>
                <DialogTitle>Delete Scene</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this scene?
                    </DialogContentText>
                    <DialogActions>
                        <FButton color='primary' onClick={() => setAskDeleteScene(null)}>Cancel</FButton>
                        <FButton color='error' onClick={confirmedDeleteScene}>Delete</FButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={!!askDeleteRender} onClose={() => setAskDeleteRender(null)}>
                <DialogTitle>Delete Render</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this render?
                    </DialogContentText>
                    <DialogActions>
                        <FButton color='primary' onClick={() => setAskDeleteRender(null)}>Cancel</FButton>
                        <FButton color='error' onClick={confirmedDeleteRender}>Delete</FButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog fullScreen open={!!renderPath} onClose={() => setRenderPath(null)} >
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setRenderPath(null)}
                        aria-label="close"
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <IconButton onClick={() => downloadRender(renderPath)}><Download /></IconButton>
                </DialogTitle>
                <DialogContent>
                    <img src={renderPath} />
                </DialogContent>
            </Dialog >            
            <Paper
                ref={componentRef}
                square={true}
                sx={{ height: "100%" }}
                style={{ display: "flex", flexGrow: 1 }}
            >
                <Stack
                    direction="column"
                    sx={{ height: "100%" }}
                    style={{ display: "flex", flexGrow: 1 }}
                >
                    <Stack
                        direction="row"
                        sx={{ flexGrow: 1, overflow: "hidden" }}
                    >
                        <ResizableContainer
                            defaultSizes={[0.3, 0.7]}
                            orientation={orientation}
                        >
                            {showFolders &&
                                <RichTreeView
                                    items={folderTree}
                                    onSelectedItemsChange={
                                        handleClickOnTreeViewItem
                                    }
                                    selectedItems={[selectedTreeFolder]}
                                    expansionTrigger="iconContainer"
                                    expandedItems={expandedTreeFolders}
                                    defaultExpandedItems={expandedTreeFolders}
                                    onExpandedItemsChange={
                                        handleExpandedItemsChange
                                    }
                                    onItemExpansionToggle={
                                        handleTreeExpansionToggle
                                    }
                                />}

                            <Box
                                ref={imageContainerRef}
                                sx={{ padding: 1, overflow: "auto" }}
                            >
                                {folderList.length > 0 && (
                                    <ImageList
                                        cols={imageColumns}
                                        gap={8}
                                    >
                                        {folderList.map((folder, index) => (
                                            <ImageListItem
                                                key={index}
                                                onClick={(e) =>
                                                    clickOnFolderIcon(
                                                        e,
                                                        folder.id,
                                                    )
                                                }
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}>
                                                    <FolderIcon
                                                        color="primary"
                                                        style={{
                                                            width: "100%",
                                                            flex: 1,
                                                        }}
                                                    />
                                                    <ImageListItemBar
                                                        title={folder.label}
                                                        position="below"
                                                    />
                                                </div>
                                                
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                )}

                                <ImageList cols={imageColumns} gap={8}>
                                    {fileList.map((file: FSEntry, index) => (
                                        <ImageListItem
                                            key={index}
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>
                                                <img
                                                    draggable={true}
                                                    // onDragStart={(e) => dragStart(index)}
                                                    // onDragEnd={(e) => onDraggingMaterial(undefined)}
                                                    src={file.thumbnail}
                                                    alt={file.label}
                                                    loading="lazy"
                                                    onClick={(e: any) =>
                                                        handleClickOnThumbnail(
                                                            e,
                                                            file,
                                                        )
                                                    }
                                                    id={file.id}
                                                    onError={(e) => {
                                                        e.target.onerror = null; // Prevents infinite loop if the fallback image also fails
                                                        e.target.src =
                                                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjmDZt2n8ABkoCwgNiyRMAAAAASUVORK5CYII=";
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        maxWidth: "128px",
                                                        maxHeight: "128px"
                                                    }}
                                                />
                                                {file.label && (
                                                    <ImageListItemBar
                                                        title={file.label}
                                                        position="below"
                                                    />
                                                )}

                                            </div>
                                            {!isMobile &&
                                                <IconButton sx={{ position: "absolute", top: '0px' }} onClick={(_e: any) => handleDelete(file)} size="small">
                                                    <GridDeleteIcon />
                                                </IconButton>}
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                        </ResizableContainer>
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
}
