import { ArrowCircleUp, Bolt, CameraAlt, CameraFront, CameraOutlined, CenterFocusStrongOutlined, FullscreenOutlined, HeightOutlined, MenuBookOutlined, ModeEditOutline, OpenWithOutlined, ReplayOutlined, SignalCellular0Bar, WidgetsOutlined } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ActiveUsers from './ActiveUsers';
import FButton from './FComponents/FButton';
import MainMenu from './MainMenu';
import MenuButton from './MenuButton';
import PrefabMenu from './PrefabMenu';
import ShareDialog from './ShareDialog';
import UserAvatar from './UserAvatar';
import { User } from './utils/user';

export type RenderMode = "Wire" | "Fast" | "Photo";

export type MenuAppBarProps = {
    onFullScreen: () => void,
    isfullScreen: boolean,
    onIconClick: () => void,
    onCenterAndFitClick: () => void,
    onRenderClick: () => void,
    onRenderModeClick: (mode: RenderMode) => void,
    onSelectPrefabModel: () => void,
    sceneId: string,
    sceneName: string,
    onChangeSceneName: (name: string) => void,
    onShowLibraryDrawer: () => void,
    onShowSceneDrawer: () => void,
    libraryDrawerOpen: boolean,
    sceneDrawerOpen: boolean,
    activeUsersList: string[],
    user: User,
    onThemeModeChange: (mode: string) => void,
    themeMode: string,
    canEdit: boolean,
}

export default function MenuAppBar
    ({
        onFullScreen,
        isfullScreen,
        onIconClick,
        onCenterAndFitClick,
        onRenderClick,
        onRenderModeClick,
        onSelectPrefabModel,
        sceneId,
        sceneName,
        onChangeSceneName,
        onShowLibraryDrawer,
        onShowSceneDrawer,
        libraryDrawerOpen,
        sceneDrawerOpen,
        activeUsersList,
        user,
        onThemeModeChange,
        themeMode,
        canEdit,
    }): MenuAppBarProps {

    const [selectedButton, setSelectedButton] = useState("Tumble");
    const [renderMode, setRenderMode] = useState<RenderMode>("Photo");
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [name, setName] = useState("");

    useEffect(() => {
        setName(sceneName)
    }, [sceneName])

    const centerAndFitClick = (e) => {
        onCenterAndFitClick(true);
    }

    const renderModeClick = (e) => {

        let newMode = e.currentTarget.id;

        if (newMode == renderMode) {
            switch (e.currentTarget.id) {
                case 'Fast':
                    newMode = "Photo";
                    break;
                case 'Photo':
                    newMode = 'Fast';
                    break;
                case 'Wire':
                    newMode = 'Fast';
                    break;
            }
        }

        onRenderModeClick(newMode);
        setRenderMode(newMode);
    }

    const renderClick = (e) => {
        onRenderClick()
    }

    const buttonGroupClick = (e) => {
        onIconClick(e.currentTarget.id);
        setSelectedButton(e.currentTarget.id);
    }

    //state.selectedButton = { value:"select" }
    const inputFile = useRef(null)
    const onImportClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };
    const onFileChange = (e) => {
        e.preventDefault();
        const reader = new FileReader();

        reader.onload = async (e) => {

            const uint8Arr = new Uint8Array(e.target.result);
            const num_bytes = uint8Arr.length * uint8Arr.BYTES_PER_ELEMENT;
            const data_ptr = window.lys._malloc(num_bytes);
            const data_on_heap = new Uint8Array(window.lys.HEAPU8.buffer, data_ptr, num_bytes);
            data_on_heap.set(uint8Arr);
            const res = window.lys.importFileModel(e.target.fileName, data_on_heap.byteOffset, uint8Arr.length);
            
        };
        reader.fileName = e.target.files[0].name;
        reader.readAsArrayBuffer(e.target.files[0]);
    }


    const handleSceneNameBlur = () => {
        if (name.trim() !== '') {
            onChangeSceneName(name);            
        }
    };

    const handleKeyDownSceneName = (e) => {
        if (e.key === 'Enter') {
            e.target.blur(); // Remove focus when Enter is pressed
        }
    };

    const handleShare = (event: MouseEvent) => {
        setShowShareDialog(!showShareDialog);
    }

    return (

        <AppBar enableColorOnDark color="neutralbackground" position={"fixed"} sx={isMobileOnly ? { top: 'auto', bottom: 0, paddingBottom: "25px" } : {}} elevation={0}>
            <Toolbar disableGutters={isMobileOnly ? undefined : true} variant="dense" >

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: "100%",
                        alignItems: "center"
                    }}
                >

                    <MainMenu themeMode={themeMode} onThemeModeChange={onThemeModeChange} canEdit={canEdit} showShareDialog={showShareDialog} setShowShareDialog={setShowShareDialog} />

                    {canEdit && <MenuButton name="Library" tooltip="Library Drawer" icon={MenuBookOutlined}
                        onClick={() => onShowLibraryDrawer(!libraryDrawerOpen)} active={libraryDrawerOpen}
                    />}
                    {canEdit && <MenuButton name="Scene" tooltip="Scene Drawer" icon={WidgetsOutlined}
                        onClick={() => onShowSceneDrawer(!sceneDrawerOpen)} active={sceneDrawerOpen}
                    />}

                    {!isMobileOnly && <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />}
                    {canEdit && <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                        <input type='file' id='file'
                            onChange={onFileChange} ref={inputFile} style={{ display: 'none' }} />
                        <MenuButton name="Import" tooltip="Import 3D/CAD files" icon={ArrowCircleUp} onClick={onImportClick} />
                        <PrefabMenu onSelectPrefabModel={onSelectPrefabModel} />
                    </Box>}

                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                        <MenuButton name={isfullScreen ? "Window" : "Fullscreen"} onClick={isfullScreen ? onFullScreen.exit : onFullScreen.enter} icon={FullscreenOutlined} />
                    </Box>

                    <MenuButton name="Center" tooltip="Fit camera to see models" shortcut="C" icon={CenterFocusStrongOutlined} onClick={centerAndFitClick} />
                  
                    {canEdit && <React.Fragment>
                        {!isMobileOnly && <Divider orientation="vertical" variant="middle" flexItem sx={{ display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }, marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />}                  
                        <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' } }}>
                            <MenuButton name="Wire" icon={SignalCellular0Bar} onClick={renderModeClick} active={renderMode} />
                        </Box>

                        <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' } }}>
                            <MenuButton name="Fast" icon={Bolt} onClick={renderModeClick} active={renderMode} />
                        </Box>
                        
                        <MenuButton name="Photo" icon={CameraOutlined} onClick={renderModeClick} active={renderMode} />
                    </React.Fragment>}

                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                        {!isMobileOnly && <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />}
                        <MenuButton name="Select" shortcut="SHIFT + LEFT MOUSE BUTTON" icon={ModeEditOutline} onClick={buttonGroupClick} active={selectedButton} />
                        <MenuButton name="Tumble" shortcut="SHIFT + ALT+LEFT MOUSE BUTTON" icon={ReplayOutlined} onClick={buttonGroupClick} active={selectedButton} />
                        <MenuButton name="Pan" shortcut="SHIFT + CTRL + LEFT MOUSE BUTTON" icon={OpenWithOutlined} onClick={buttonGroupClick} active={selectedButton} />
                        <MenuButton name="Dolly" shortcut="ALT + LEFT MOUSE BUTTON" icon={HeightOutlined} onClick={buttonGroupClick} active={selectedButton} />
                        {/*<MenuButton name="Zoom" shortcut="CTRL + LEFT MOUSE BUTTON"  icon={ZoomInOutlined} onClick={buttonGroupClick} active={selectedButton}/>*/}
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />
                    <MenuButton name="Render" icon={CameraAlt} onClick={renderClick} />


                    {!isMobileOnly &&
                        <InputBase
                            sx={{
                                flex: 2,
                                color: '#999999',
                                fontSize: 14,
                                textAlign: 'center',
                                align: 'center',
                                '& input': { textAlign: "center" },
                                marginLeft: 0,
                                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
                            }}

                            onBlur={handleSceneNameBlur}
                            onKeyDown={handleKeyDownSceneName}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Untitled"
                            value={name}
                        />
                    }

                    <ActiveUsers activeUsersList={activeUsersList} />

                    {canEdit && <FButton
                        startIcon={<GroupIcon />}
                        sx={{
                            marginRight: 1,
                            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }
                        }}
                        onClick={handleShare} >
                        Share
                    </FButton>}

                    <ShareDialog user={user} sceneId={sceneId} open={showShareDialog} onClose={() => setShowShareDialog(false)} />
                    <UserAvatar user={user} followUserId={0} />
                </Box>
            </Toolbar>
        </AppBar>

    )

}