import ModelIcon from "@mui/icons-material/Chair";
import ImageIcon from "@mui/icons-material/Image";
import EnvironmentIcon from "@mui/icons-material/Language";
import LightIcon from "@mui/icons-material/Lightbulb";
import ColorIcon from "@mui/icons-material/Palette";
import { Paper, Tab, Tabs } from "@mui/material";
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useState } from "react";
import AssetManager from "./AssetManager";

import MaterialIcon from "@mui/icons-material/SportsSoccer";
import { RELATIVE_RENDERS_PATH, RELATIVE_SCENES_PATH } from "./App";
import { CameraAlt } from "@mui/icons-material";

const minDrawerWidth = 200;
const maxDrawerWidth = 1000;

const tabFileType = 
    [
        ".scene,.share.toml",
        ".materials",
        ".color",
        ".png,.jpg,.jpeg,.tga",
        ".environment",
        ".light",
        ".jpg",
    ]

export enum Mode {
    scenes = 0,
    materials = 1,
    colors = 2,
    textures = 3,
    envs = 4,
    lights = 5,
    renders = 6,
}

export default function LibraryDrawer({
    drawerWidth,
    onDrawerWidthChange,
    height,
    materialList,
}) {
    const [selectedTab, setSelectedTab] = useState<Mode>(Mode.scenes);
    const [filetypes, setFiletypes] = useState(tabFileType[selectedTab]);

    const handleTabSelect = (_event: any, newSelectedTab: number) => {

        setFiletypes(tabFileType[newSelectedTab]);
        setSelectedTab(newSelectedTab);
    };

    const handleMouseDown = (_e: any) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback((e: MouseEvent) => {
        window.getSelection().removeAllRanges();
        const newWidth = e.clientX - document.body.offsetLeft;
        onDrawerWidthChange(newWidth);
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            onDrawerWidthChange(newWidth);
        }
    }, []);

    useEffect(() => {
        console.log("LibraryDrawer::useEffect materialList", materialList)
    }, [materialList])

    return (
        <Paper square={true} >
            <Stack
                width={drawerWidth}
                minWidth={350}
                height={height}
                maxHeight={height}
                minHeight={height}
                alignItems="stretch"
                justifyContent="space-between"
                direction="row"
            >
                <Stack sx={{ marginRight: "-3px", width: "100%" }}>
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        onChange={handleTabSelect}
                        aria-label="icon tabs example"
                    >
                        <Tab
                            icon={<ModelIcon />}
                            aria-label="models"
                            label="Scenes"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<MaterialIcon />}
                            aria-label="materials"
                            label="Materials"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<ColorIcon />}
                            aria-label="colors"
                            label="Colors"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<ImageIcon />}
                            aria-label="images"
                            label="Textures"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<EnvironmentIcon />}
                            aria-label="environments"
                            label="Envs"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<LightIcon />}
                            aria-label="lights"
                            label="Lights"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<CameraAlt />}
                            aria-label="renders"
                            label="Renders"
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                    </Tabs>
                    {selectedTab === Mode.scenes && 
                        <AssetManager
                            showFolders={false}
                            recursive={true}
                            filetypes={filetypes}
                            selectedPath={RELATIVE_SCENES_PATH}
                        />}
                    {selectedTab === Mode.renders && 
                        <AssetManager
                            showFolders={false}
                            recursive={true}
                            selectedPath={RELATIVE_RENDERS_PATH} filetypes={'.jpg'} />
                    }
                </Stack>
                <Stack onMouseDown={e => handleMouseDown(e)} sx={{ width: "3px", cursor: "ew-resize" }} ></Stack>
            </Stack>
        </Paper>
    )
}
