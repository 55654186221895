import ocFullJS from "./lib/opencascade_custom.js"
// @ts-expect-error
import ocFullWasm from "./lib/opencascade_custom.wasm?url"

export class StepImporter{
    constructor(){
        // @ts-expect-error
        this.ocInstance = ocFullJS({ locateFile: function () { return ocFullWasm } });
    }

    /**
     * Step to glb converter
     * @param {ArrayBuffer} data 
     * @returns {Uint8Array} glb data after conversion
     */
    async importStep(data) {
        const oc = await this.ocInstance;

        const reader = new oc.STEPCAFControl_Reader_1();

        oc.FS.createDataFile("/", "model.stp", data, true, true, true);
        reader.ReadFile("/model.stp")
        console.debug("file loaded successfully!     Converting to OCC now...");

        const docHandle = new oc.Handle_TDocStd_Document_2(new oc.TDocStd_Document(new oc.TCollection_ExtendedString_1()));
        reader.Transfer_1(docHandle, new oc.Message_ProgressRange_1());
        //const numRootsTransferred = reader.TransferRoots(new oc.Message_ProgressRange_1());    // Translate all transferable roots to OpenCascade
        //const shape = reader.OneShape();         // Obtain the results of translation in one OCCT shape
        console.debug(" converted successfully!  Triangulating now...");


        const shapeTool = oc.XCAFDoc_DocumentTool.ShapeTool(docHandle.get().Main()).get();
        const rootLabels = new oc.TDF_LabelSequence_1();
        shapeTool.GetFreeShapes(rootLabels);

        const shape = new oc.TopoDS_Shape();
        oc.XCAFDoc_ShapeTool.GetShape_1(rootLabels.Value(1), shape)

        // Tell OpenCascade that we want our shape to get meshed
        new oc.BRepMesh_IncrementalMesh_2(shape, 0.01, false, 0.5, false);

        // Export a GLB file (this will also perform the meshing)
        const cafWriter = new oc.RWGltf_CafWriter(new oc.TCollection_AsciiString_2("./file.glb"), true);
        cafWriter.Perform_2(docHandle, new oc.TColStd_IndexedDataMapOfStringString_1(), new oc.Message_ProgressRange_1());
        // Read the GLB file from the virtual file system
        const glbFile = oc.FS.readFile("./file.glb", { encoding: "binary" });
        oc.FS.unlink("model.stp");
        oc.FS.unlink("file.glb");
        return glbFile;

        const blob = new Blob([glbFile.slice().buffer], { type: "model/gltf-binary" })

        const url = URL.createObjectURL(blob);
        console.log(url)
        console.log(glbFile.slice().buffer)

        oc.FS.unlink("model.stp");
        oc.FS.unlink("file.glb");
        return blob;
    }
}